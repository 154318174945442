@use '@/styles/tokens' as *;
@use '@/styles/mixins/media-queries' as *;

.nps {
  padding: $spacing-xxxs;
  border-radius: $border-radius-sm;
  border: $border-width-hairline solid $color-neutral-lightest;

  @include minScreenSize(desktop) {
    padding: $spacing-xxs;
  }
}
