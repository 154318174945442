@use '@/styles/tokens' as *;
@use '@/styles/mixins/media-queries' as *;

.topContent {
  display: flex;
  align-items: center;
  gap: $spacing-xxs;
  padding: $spacing-xxxs $spacing-xxs;
  border-bottom: $border-width-thin solid $color-neutral-lightest;

  @include minScreenSize(desktop) {
    border: none;
    gap: $spacing-xl;
  }

  &__logo {
    line-height: normal;
  }

  &__whatsappButton {
    width: fit-content;
    margin-left: auto;

    & > img {
      width: 32px;
      height: 32px;
    }

    @include minScreenSize(desktop) {
      display: none;
    }
  }
}
