@use '@/styles/tokens/' as *;

.radio {
  width: 24px;
  height: 24px;
  padding: 5px;
  outline: none;
  appearance: none;
  cursor: pointer;
  border-radius: $border-radius-circular;
  border: $border-width-thin solid $color-neutral-brightest;

  &:checked {
    border-color: $color-neutral-brightest;
    background: $color-neutral-brightest content-box;
  }
}
