@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;
.verification {
  &__form {
    width: 100%;
  }
  &__resendMail {
    margin: $spacing-xxxs 0;
  }
  &__actionText {
    @include typography($font-size-xs, $line-height-xxs);
    margin-bottom: $spacing-micro;
  }
  &__actionButton {
    @include typography(
      $font-size-xs,
      $line-height-xxs,
      $font-weight-medium,
      $color-blue-primary
    );
    width: fit-content;
    display: flex;
    align-items: center;
    gap: $spacing-quarck;
  }
}

.email {
  &__title,
  &__sentTo {
    @include typography($font-size-sm, $line-height-sm, $font-weight-bold);
  }

  &__infos {
    display: flex;
    align-items: center;
    gap: $spacing-xxxs;
    margin: $spacing-nano 0;
  }

  &__address {
    @include typography($font-size-xs, $line-height-xxs);
  }

  &__icon {
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: $border-width-hairline solid $color-neutral-light;
    border-radius: $border-radius-circular;
    background-color: $color-neutral-brightest;
  }
}

.hide {
  display: none;
}
