@use '@/styles/mixins' as *;
@use '@/styles/tokens' as *;

.help {
  padding: $spacing-xxs 0;

  @include minScreenSize(desktop) {
    padding: $spacing-xs 0;
  }

  &__title {
    margin-top: $spacing-xxs;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: $spacing-xxs;
    margin-top: $spacing-xs;

    @include minScreenSize(desktop) {
      flex-direction: row;
      margin-top: $spacing-md;

      & > div {
        max-width: 50%;
      }
    }
  }
}
