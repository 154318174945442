@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.conclusion {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;

  &__title,
  &__subtitle {
    text-align: center;
  }

  &__title {
    @include typography($font-size-md, $line-height-md, $font-weight-medium);
    margin-top: $spacing-xs;
  }

  &__subtitle {
    @include typography($font-size-xs, $line-height-xxs);
    margin-top: $spacing-nano;
  }

  &__icon {
    width: 72px;
    height: 72px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: $border-width-hairline solid $color-neutral-light;
    border-radius: $border-radius-circular;
    background-color: $color-neutral-brightest;
  }
}
