@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.tagImage {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  z-index: $z-index-lower;
  &__container {
    @include typography($font-size-xxs, $line-height-xxs, $font-weight-medium);
    z-index: $z-index-low;
    background-image: linear-gradient(
      #ededffae 10%,
      #ededff68 48%,
      #ededff5f 0%
    );
    backdrop-filter: blur(4px);
    padding: $spacing-nano;
    border: $border-width-hairline solid $color-neutral-brightest;
    border-bottom: none;
    border-radius: $border-radius-md;
    box-shadow: 0 2px 12px 2px #00000018;
    display: flex;
    align-items: center;
  }

  &__dot {
    width: 6px;
    height: 6px;
    border-radius: $border-radius-circular;
    background-color: $color-neutral-bright;
  }
}
.line {
  height: 0;
  position: relative;
  z-index: $z-index-lower;
  &__container {
    display: flex;
    align-items: center;
    width: auto;
    &--vertical {
      transform: rotate(90deg);
      position: absolute;
      top: 50px;
      &--fill {
        border: $border-width-hairline solid #3347ad7e;
        position: absolute;
        animation: grow 2s ease-in-out infinite alternate;
      }
    }
    &--horizontal {
      &--fill {
        border: $border-width-hairline solid #3347ad7e;
        position: absolute;
        animation: grow 2s ease-in-out infinite alternate;
      }
    }
    &--diagonal {
      transform: rotate(35deg);
      position: relative;
      left: 12px;
      margin-top: 40px;

      .line__size--medium {
        width: 65px;
        @include onMobile() {
          width: 45px;
        }
      }

      &::before {
        content: '';
        width: 18px;
        border: $border-width-hairline dashed $color-blue-bright;
        transform: rotate(145deg);
        position: absolute;
        right: 65px;
        bottom: -3px;

        @include maxScreenSize(mobile) {
          right: 50px;
          width: 20px;
        }
      }

      &--fill {
        border: 0.5 solid #3347ad7e;
        position: absolute;
        animation: grow 2s ease-in-out infinite alternate;
      }
    }
  }
  &__size {
    &--small {
      width: 28px;
    }
    &--medium {
      width: 60px;
      @include onMobile() {
        width: 30px;
      }
    }
    &--big {
      width: 100px;
      @include onMobile() {
        width: 50px;
      }
    }
    &--large {
      width: 180px;
      @include onMobile() {
        width: 100px;
      }
    }
  }
  &__color {
    &--white {
      border: $border-width-hairline dashed $color-neutral-bright;
    }
  }
}

@keyframes grow {
  0% {
    width: 75%;
  }
  100% {
    width: 0;
  }
}
