@use '@/styles/tokens' as *;
@use '@/styles/mixins/media-queries' as *;

.link {
  padding: $spacing-xxs 0 $spacing-xxxs;
  position: relative;
  bottom: -8px;
  font-size: $font-size-md;
  border-bottom: $border-width-hairline solid $color-neutral-bright;

  @include minScreenSize(desktop) {
    border-bottom: $border-width-thin solid $color-neutral-brightest !important;
    font-size: $font-size-sm;
    padding: 0 0 $spacing-quarck;
    font-weight: $font-weight-bold;

    &::after {
      content: '';
      position: absolute;
      height: $spacing-atom;
      bottom: -2px;
      left: 0;
      right: 0;
      transform: scaleX(0);
      transition: transform 0.3s;
      background-color: $color-neutral-dark;
    }
  }

  &:hover {
    cursor: pointer;

    &:not(.link--active):after {
      transform: scaleX(1);
    }
  }
}