@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.softlead {
  @include maxScreenSize(desktop) {
    flex: 1;
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__submitButton {
    margin-top: $spacing-micro;
  }

  &__terms {
    @include typography($font-size-xxs, $line-height-xxs);

    padding: $spacing-nano;
    border-radius: $border-radius-sm;
    background-color: $color-neutral-bright;
  }
}
