@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.unsubscribe {
  display: flex;
  flex-direction: column;
  gap: $spacing-xxs;
  justify-content: center;
  align-items: center;
  padding-top: $spacing-sm;
  margin: 0 auto 150px;
  width: fit-content;

  @include onTablet() {
    padding: $spacing-xxxl;
  }

  @include maxScreenSize(desktop) {
    margin-bottom: 10px;
    padding: $spacing-xxs 0;
  }

  &__title {
    color: $color-neutral-dark;
    font-size: $font-size-xl;
    line-height: $line-height-xl;

    @include maxScreenSize(desktop) {
      font-size: $font-size-lg;
      line-height: $line-height-lg;
      text-align: center;
    }

    &--container {
      display: flex;
      gap: $spacing-xxxs;
    }
  }

  &__subtitle {
    font-size: $font-size-sm;
    line-height: $line-height-xs;
    background-color: $color-neutral-bright;
    padding: $spacing-xxxs;
    border-radius: $border-radius-md;
    max-width: 645px;
    text-align: center;
    color: $color-neutral-dark;
  }

  &__buttons {
    width: 100%;
    margin-top: 0;
    gap: $spacing-xxxs;

    @include onMobile() {
      min-width: 100%;
      flex-direction: column;
      gap: $spacing-xxxs;
    }
  }
}
