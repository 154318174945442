@use '@/styles/tokens/' as *;

.icon {
  --icon-color: inherit;
  color: --icon-color;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &[data-color='blue'] {
    --icon-color: $color-blue-primary;
    svg {
      fill: $color-blue-primary;
    }
  }
  &[data-color='primary'],
  &[data-color='red'] {
    --icon-color: $color-red-primary;
    svg {
      fill: $color-red-primary;
    }
  }
  &[data-color='secondary'],
  &[data-color='white'] {
    --icon-color: $color-neutral-bright;
    svg {
      fill: $color-neutral-bright;
    }
  }
  &[data-color='yellow'] {
    --icon-color: $color-yellow-light;
    svg {
      fill: $color-yellow-light;
    }
  }
  &[data-color='green'] {
    --icon-color: $color-green-light;
    svg {
      fill: $color-green-light;
    }
  }
  &[data-color='error'] {
    --icon-color: $color-red-light;
    svg {
      fill: $color-red-light;
    }
  }
  &[data-color='orange'] {
    --icon-color: $color-orange-primary;
    svg {
      fill: $color-orange-primary;
    }
  }

  &[data-color='gray'] {
    --icon-color: $color-neutral-primary;
    svg {
      fill: $color-neutral-primary;
    }
  }
  &[data-color='black'] {
    --icon-color: $color-neutral-dark;
    svg {
      fill: $color-neutral-dark;
    }
  }
  &[data-color='inactive'] {
    --icon-color: $color-neutral-bright;
    svg {
      fill: $color-neutral-bright;
    }
  }

  &__size {
    &--xxxs {
      min-height: $icon-size-xxxs;
      min-width: $icon-size-xxxs;
      max-height: $icon-size-xxxs;
      max-width: $icon-size-xxxs;

      > svg {
        height: $icon-size-xxxs;
      }
    }

    &--xxs {
      min-height: $icon-size-xxs;
      min-width: $icon-size-xxs;
      max-height: $icon-size-xxs;
      max-width: $icon-size-xxs;

      > svg {
        height: $icon-size-xxs;
      }
    }

    &--xs {
      min-height: $icon-size-xs;
      min-width: $icon-size-xs;
      max-height: $icon-size-xs;
      max-width: $icon-size-xs;

      > svg {
        height: $icon-size-xs;
      }
    }
    &--s {
      min-height: $icon-size-s;
      min-height: $icon-size-s;
      max-width: $icon-size-s;
      max-width: $icon-size-s;

      > svg {
        height: $icon-size-s;
        width: $icon-size-s;
      }
    }
    &--giant {
      min-height: $icon-size-giant;
      min-width: $icon-size-giant;
      max-height: $icon-size-giant;
      max-width: $icon-size-giant;

      > svg {
        height: $icon-size-xl;
        width: $icon-size-xl;
      }
    }

    &--small {
      min-height: $icon-size-sm;
      min-width: $icon-size-sm;
      max-height: $icon-size-sm;
      max-width: $icon-size-sm;

      > svg {
        height: $icon-size-sm;
      }
    }

    &--medium {
      min-height: $icon-size-md;
      min-width: $icon-size-md;
      max-height: $icon-size-md;
      max-width: $icon-size-md;

      > svg {
        height: $icon-size-md;
      }
    }

    &--large {
      min-height: $icon-size-lg;
      min-width: $icon-size-lg;
      max-height: $icon-size-lg;
      max-width: $icon-size-lg;

      > svg {
        height: $icon-size-lg;
      }
    }
  }

  &__background {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease 500ms;
    width: $spacing-xl;
    height: $spacing-xl;
    padding: $spacing-nano;
    border-radius: $border-radius-circular;
    box-shadow: 0px 0px 16px 0px #333e481a;

    > svg {
      margin: auto;
    }
    &--round {
      border-radius: 50%;
      width: 48px;
      height: 48px;
    }
    &--red {
      @extend .icon__background;
      background-color: $color-red-primary;
    }
    &--blue {
      @extend .icon__background;
      background-color: $color-blue-light;
    }
    &--yellow {
      @extend .icon__background;
      background-color: $color-yellow-light;
    }
    &--transparent {
      @extend .icon__background;
      background-color: transparent;
      border: 1px solid $color-neutral-light;
    }
    &--gray {
      @extend .icon__background;
      background-color: $color-neutral-light;
    }
    &--white {
      @extend .icon__background;
      background-color: $color-neutral-brightest;
    }
    &--inactive {
      @extend .icon__background;
      background-color: $color-neutral-bright;
    }
  }
}
