@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

@keyframes progress {
  from {
    width: 0;
  }
}

.document {
  position: relative;
  height: $spacing-md;
  margin-bottom: $spacing-nano;
  border-radius: $border-radius-sm;
  border: $border-width-hairline solid $color-neutral-lightest;
  background: $color-neutral-bright;

  &--error {
    border-color: $color-red-light;
  }

  &__field {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__label {
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: $spacing-nano;
    padding: 0 $spacing-xxxs;
  }

  &__holder {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
  }

  &__message {
    @include typography($font-size-xs, $line-height-xxs);

    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $color-neutral-dark;

    &--progress {
      @include typography($font-size-xxs, $line-height-xxs);
    }
  }

  &__progress {
    width: 100%;
    height: $spacing-quarck;
    border-radius: $border-radius-sm;
    background-color: $color-neutral-lightest;

    &::before {
      content: '';
      display: block;
      width: 80%;
      height: 100%;
      animation: progress 10s ease;
      border-radius: $border-radius-sm;
      background-color: $color-blue-primary;
    }
  }

  &__removeFileButton {
    cursor: pointer;
    position: absolute;
    top: 6px;
    right: 8px;

    &:hover {
      transition: background-color 300ms ease;
      border-radius: $border-radius-sm;
      background-color: $color-neutral-bright;
    }
  }

  &__errorMessage {
    @include typography(
      $font-size-xxs,
      $line-height-nano,
      $font-weight-regular,
      $color-red-light
    );
  }
}
