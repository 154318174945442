@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.plan {
  height: fit-content;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-sm;
  border: $border-width-hairline solid $color-neutral-dark;

  &--withCta {
    min-height: 396px;
  }

  &--clickable {
    cursor: pointer;

    div {
      pointer-events: none;
    }

    button,
    a,
    label {
      pointer-events: all;
    }
  }

  &--active {
    border-color: $color-red-primary;
  }

  &--active &__heading {
    background-color: $color-red-primary;
  }

  &__heading {
    @include typography(
      $font-size-sm,
      $line-height-sm,
      $font-weight-medium,
      $color-neutral-brightest
    );

    display: flex;
    justify-content: space-between;
    transition: background-color ease 200ms;
    padding: $spacing-nano $spacing-xxxs;
    background-color: $color-neutral-dark;
  }

  &__icon {
    vertical-align: middle;
    margin-right: $spacing-nano;
  }

  &__badge {
    margin: 0 $spacing-xxxs 0 auto;
  }

  &__discount {
    @include typography(
      $font-size-sm,
      $line-height-sm,
      $font-weight-medium,
      $color-green-dark
    );

    text-align: center;
    padding: $spacing-quarck;
    background-color: $color-green-bright;
  }

  &__highlightText {
    @include typography($font-size-xxxl, $line-height-xxl, $font-weight-bold);
  }

  &__subscription {
    @include typography($font-size-xs, $line-height-xxs, $font-weight-medium);
  }

  &__text:not(:empty) {
    @include typography($font-size-xs, $line-height-xxs);
    margin: $spacing-nano 0;
  }

  &__list {
    @include typography($font-size-xs, $line-height-xxs);

    list-style-type: disc;
    padding-left: $spacing-xxxs;
  }

  &__content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: $spacing-xxxs;
    padding: $spacing-nano $spacing-xxxs $spacing-xxxs;

    &--emptyDiscount {
      margin-top: $spacing-xs;

      @include onMobile() {
        margin-top: $spacing-nano;
      }
    }
  }
}
