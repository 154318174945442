@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.feedback {
  &__icon {
    width: 100%;
    text-align: center;
  }

  &__title,
  &__text {
    @include typography($font-size-xs, $line-height-xxs);
    text-align: center;
  }

  &__title {
    font-weight: $font-weight-medium;
  }

  &__text {
    margin-top: $spacing-nano;
  }
}
