@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.postpaidAdvantages {
  padding: $spacing-xxs 0;
  background-color: $color-neutral-bright;

  @include minScreenSize(desktop) {
    padding: $spacing-xs 0 $spacing-sm;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: $spacing-xs;
    gap: $spacing-xxs;

    @include minScreenSize(tablet) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__title {
    @include typography($font-size-md, $line-height-md, $font-weight-medium);

    display: flex;
    gap: $spacing-micro;

    @include minScreenSize(tablet) {
      @include typography($font-size-xl, $line-height-xl, $font-weight-medium);
    }
  }
}
