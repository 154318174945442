@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

@keyframes fall {
  0% {
    transform: translate(-90px, -100px);
  }
  30% {
    transform: translate(0px, -80px);
  }
  50% {
    transform: translate(-50px, -50px);
  }
  70% {
    transform: translate(25px, -30px);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(25deg);
  }
  50% {
    transform: rotate(-25deg);
  }
  75% {
    transform: rotate(25deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes throw {
  0% {
    transform: scale(0) translateX(100px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateX(0);
    opacity: 1;
  }
}

.image {
  position: relative;
  padding: 110px $spacing-xxl 0 0;
  margin-top: $spacing-lg;

  @media (max-width: 340px) {
    padding-left: $spacing-xxl;
  }

  &__letters {
    position: absolute;
    top: 75px;
    right: 78px;
    animation: grow 5s infinite;
  }
  &__message {
    position: absolute;
    top: 50px;
    left: 78px;
    animation: shake 5s infinite;

    &-empty {
      position: absolute;
      top: 60px;
      right: 78px;
      animation: shake 5s infinite;
    }
  }
  &__letter {

    &--send {
      position: absolute;
      top: 0;
      right: 0;
      animation: throw 5s ease-in-out forwards;
    }

    &--white {
      position: absolute;
      bottom: 25px;
      right: 30px;
      animation: fall 8s ease-in-out forwards;
    }

    &--yellow {
      position: absolute;
      bottom: 0;
      right: 0;
      animation: fall 10s ease-in-out forwards;
    }
    
    &--big {
      animation: fall 5s ease-in-out forwards;
      @media (max-width: 340px) {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
}
