@use '@/styles/tokens' as *;

.menu {
  display: flex;
  flex-direction: column;
  gap: $spacing-quarck;
  height: $spacing-xxs;
  margin-right: $spacing-xxxs;
  justify-content: center;
  outline: none;

  &__line1 {
    width: $spacing-xxxs;
    height: $spacing-atom;
    background-color: $color-neutral-dark;
    animation: line1ToHambuger 0.3s forwards;
  }

  &__line2 {
    width: $spacing-xxxs;
    height: $spacing-atom;
    background-color: $color-neutral-dark;
    animation: line2ToHambuger 0.3s forwards;
  }

  &__line3 {
    width: $spacing-xxxs;
    height: $spacing-atom;
    background-color: $color-neutral-dark;
    animation: line3ToHambuger 0.3s forwards;
  }

  &--active {
    .menu__line1 {
      animation: line1ToX 0.3s forwards;
    }

    .menu__line2 {
      animation: line2ToX 0.3s forwards;
    }

    .menu__line3 {
      animation: line3ToX 0.3s forwards;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

@keyframes line1ToX {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(45deg) translate(6px, 1px);
  }
}

@keyframes line2ToX {
  0% {
    scale: 1;
  }

  100% {
    scale: 0;
  }
}

@keyframes line3ToX {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-45deg) translate(8px, -2.5px);
  }
}

@keyframes line1ToHambuger {
  0% {
    transform: rotate(45deg) translate(6px, 1px);
  }

  100% {
    transform: rotate(0deg) translate(0, 0);
  }
}

@keyframes line2ToHambuger {
  0% {
    scale: o;
  }

  100% {
    scale: 1;
  }
}

@keyframes line3ToHambuger {
  0% {
    transform: rotate(-45deg) translate(8px, -2.5px);
  }

  100% {
    transform: rotate(0deg) translate(0, 0);
  }
}