@use '@/styles/tokens' as *;
@use '@/styles/mixins/media-queries' as *;

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-medium;
  background-color: $color-neutral-brightest;
  width: 100%;
  transition: all 0.3s ease-in-out;
  min-height: 56px;

  &__desktop {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include minScreenSize(desktop) {
      justify-content: space-between;
      flex-direction: row;
      min-height: 74px;
    }

    &--logoImage {
      display: flex;
      flex: 1;

      @include minScreenSize(desktop) {
        flex: auto;
      }
    }

    &--hidden {
      display: flex;

      @include minScreenSize(desktop) {
        display: none;
      }
    }
  }

  &__content {
    min-height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__menu {
    display: none;
    flex-direction: column;
    flex: 1;
    width: 100%;
    z-index: $z-index-highest;
    background: $color-neutral-brightest;
    justify-content: space-between;
    padding-bottom: $spacing-xxs;

    @include minScreenSize(desktop) {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding-bottom: 0;
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
      gap: 16px;
    }

    &--hidden {
      @include minScreenSize(desktop) {
        display: none;
      }
    }

    &--isOpen {
      display: flex;
    }

    &--alignCenter {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 16px;

      @include minScreenSize(desktop) {
        flex-direction: row;
      }
    }

    &--minWidth {
      min-width: fit-content;
    }

    &--inverterButton {
      @include minScreenSize(desktop) {
        flex-direction: row-reverse;
      }
    }
  }

  &__whatsapp {
    padding: 0;
    width: auto;
    display: flex;

    @include minScreenSize(desktop) {
      display: none;
    }
  }

  &__whatsappDesktop {
    min-width: 235px;
    min-height: 46px;

    @include maxScreenSize(desktop) {
      background-color: $color-blue-primary;
      border-color: $color-blue-primary;
    }

    @include minScreenSize(desktop) {
      min-width: 250px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
