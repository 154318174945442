@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.paywall {
  &__form {
    margin-top: $spacing-quarck;
  }

  &__details {
    max-width: 530px;
    margin-top: $spacing-xxs;

    p,
    li {
      @include typography($font-size-xxs, $line-height-xxs);
    }

    li b,
    a {
      font-weight: $font-weight-medium;
    }

    a {
      color: $color-blue-primary;
    }

    li:not(:last-of-type) {
      margin-bottom: $spacing-quarck;
    }
  }
}
